
  import { defineComponent, computed, onMounted, watch } from "vue";
  import { Form, Field, ErrorMessage } from "vee-validate";
  import ApiService from "@/core/services/ApiService";
  import { useBus } from '@/bus';
  import $ from 'jquery';
  import 'datatables.net';
  import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { VueCookieNext } from "vue-cookie-next";
  import KTEditor from "@/layout/toolbar/QuillEditor.vue";
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { format } from 'date-fns-tz';
 
export default defineComponent({
    name: "Received Task",
    components: {
      Form,
      Field,
      ErrorMessage,
      Datatable,
      KTEditor,
    },
    data() {
      return {
        loading: false,
        taskList: [] as any,
        initData:[] as any,
        taskKeyUpdate: 0,
        search: '',
        componentKey:0,
        user:[] as any,
        employee_id: '' as any,
        role_id:'' as any,
        all_cookies: '' as any,
        filterStatus: '' as any,
        deadline:'' as any,
        warning: false,
        date: new Date(),
        tableHeader: [
          {
            name: 'TASK No.',
            key: 'index',
          },
          {
            name: 'DISTRICT',
            key: 'district_name',
          },
          {
            name: 'UPAZILLA',
            key: 'upazila_name',
          },
          {
            name: 'GRID',
            key: 'grid_name',
          },
          {
            name: 'DEADLINE',
            key: 'deadline',
          },
          {
            name: 'ASSIGNED BY',
            key: 'assigned_by',
          },
          {
            name: 'ASSIGNING DATE',
            key: 'assigning_date',
          },
          {
            name: 'Actions',
            key: 'actions',
          },
        ],
        tableHeader1: [
          {
            name: 'TASK No.',
            key: 'index',
          },
          {
            name: 'DISTRICT',
            key: 'district_name',
          },
          {
             name: 'UPAZILLA',
             key: 'upazila_name',
          },
          {
            name: 'Mouza',
            key: 'mouza_name',
          },
          {
            name: 'DEADLINE',
            key: 'deadline',
          },
          {
            name: 'ASSIGNED BY',
            key: 'assigned_by',
          },
          {
            name: 'ASSIGNING DATE',
            key: 'assigning_date',
          },
          {
            name: 'Actions',
            key: 'actions',
          },
        ],
        tableHeader2: [
          {
            name: 'TASK No.',
            key: 'index',
          },
          {
            name: 'DEADLINE',
            key: 'deadline',
          },
          {
            name: 'ASSIGNED BY',
            key: 'assigned_by',
          },
          {
            name: 'ASSIGNING DATE',
            key: 'assigning_date',
          },
          {
            name: 'Actions',
            key: 'actions',
          },
        ]
      };
    },
    async created() {
      await this.getTaskList();
      this.initData= this.taskList;
      await this.pushWarnings();

    },
    methods: {
      formatDate(dateString) {
      return dateString ? format(new Date(dateString), 'dd-MMM-yyyy') : '';
    },
      async pushWarnings() {
        const today = new Date();
        this.taskList.forEach(element => {
        const deadlineDate = new Date(element.deadline);
        const startTime = today.getTime(); // Get timestamp of start date
        const endTime = deadlineDate.getTime(); // Get timestamp of end date
        const differenceInMs = Math.abs(endTime - startTime);
        const oneDay = 24 * 60 * 60 * 1000;
        const daysDifference = Math.round(differenceInMs / oneDay);
        console.log(daysDifference);

            if (daysDifference <= 2) {
                // Add warning property directly to the element object
                element.warning = { isActive: true, difference: daysDifference };
            }
        });
      },

      async getTaskList() {
        this.loading=true;
        this.user= VueCookieNext.getCookie("_seip_employee_id");
        this.employee_id= VueCookieNext.getCookie("_seip_employee_id");
        this.role_id= VueCookieNext.getCookie("_seip_role_id");
       
        await ApiService.get("configurations/task/list?employee_id="+ this.employee_id +"&current_status=1")
          .then((response) => {
            this.taskList = response.data.data;
            this.taskKeyUpdate+=1;
            this.loading=false
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      async rsApprove(taskDetail) {
          let formData = new FormData();
        
          formData.set('task_detail_id', taskDetail?.id);
          formData.set('current_status', taskDetail?.current_status);
          formData.set('assigned_to', taskDetail?.assigned_by);
          formData.set('assigned_by', taskDetail?.assigned_to);
          formData.set('task_type', taskDetail?.task_type);
    
          await ApiService.post("configurations/task/rs-approve", formData)
            .then((response) => {
              this.loading = false;
              if (response.data.status == 'success') {
                Swal.fire({
                  text: 'Approve Successfully.',
                  icon: "success",
                  buttonsStyling: false,
                  heightAuto: false,
                  customClass: {
                    confirmButton: "btn fw-semobold btn-success",
                  },
                }).then(() => {
                 
                    this.$router.push('/tasks/completed-tasks');
            });
              }
              else {
                Swal.fire({
                  text: 'Something Went Wrong!',
                  icon: "warning",
                  buttonsStyling: false,
                  heightAuto: false,
                  customClass: {
                    confirmButton: "btn fw-semobold btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
          },
      assignQc(taskDetail){
        const { bus } = useBus();
        let value = {
          taskDetail: taskDetail,
        };
        bus.emit('task-data-assign', value);
      },
      taskUpload(task){
        const { bus } = useBus();
        let value = {
          taskDetail: task,
        };
        bus.emit('task-data-upload', value);
      },
      receivedTaskView(task){
        const { bus } = useBus();
        let value = {
          taskDetail: task,
        };
        bus.emit('received-data-view', value);
      },

      filterTasks() {
        if(this.search=='')
        {
          this.taskList= this.initData;
          this.taskKeyUpdate +=1;
        }
        const tasks = this.taskList.filter(taskDetail => {
          return (
            taskDetail.deadline.toLowerCase().includes(this.search.toLowerCase()) ||
            taskDetail?.task?.upazila_info?.upazila_name_eng.toLowerCase().includes(this.search.toLowerCase()) ||
            taskDetail?.task?.grid_info?.grid_name.toLowerCase().includes(this.search.toLowerCase()) ||
            taskDetail?.assigning_date.toLowerCase().includes(this.search.toLowerCase()) ||
            taskDetail?.assign_by_employee_info?.name.toLowerCase().includes(this.search.toLowerCase())
          );
        });

        this.taskList= tasks;
        this.taskKeyUpdate +=1;
      },
    },
  });
  